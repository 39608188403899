import type { ImageLoader, ImageProps } from 'next/image'

export function extractSource(src: ImageProps['src']) {
	if (typeof src === 'object') {
		if ('src' in src) {
			return src.src
		}
		if ('default' in src) {
			return src.default.src
		}
	}

	return src
}

export const scene7Loader: ImageLoader = ({ quality, src, width }) => {
	const url = new URL(src)

	url.searchParams.set('qlt', `${quality ?? 75}`)
	// TODO: determine how best to implement the webp fallback when targeting avif
	// url.searchParams.set('fmt', 'avif')

	const wid = Number(url.searchParams.get('wid') ?? 0)

	// Only adjust size if incoming width is smaller
	if (width < wid) {
		const hei = Number(url.searchParams.get('hei') ?? 0)
		const height = Math.round(width * (hei / wid))

		url.searchParams.set('wid', `${width}`)
		url.searchParams.set('hei', `${height}`)
		url.searchParams.set('size', `${width},${height}`)
	}

	return url.toString()
}

export function getLoader(src: ImageProps['src']): ImageLoader | undefined {
	const source = extractSource(src)

	if (source.includes('scene7') || source.includes('loremflickr')) return scene7Loader
	return undefined
}
