import { LocaleLink, type LocaleLinkProps } from '~/components/primitives/LocaleLink/LocaleLink'
import type { ButtonVariant, ButtonVariantProps } from '~/components/primitives/Button/Button'
import React, { forwardRef, useMemo } from 'react'
import { getVariantState } from '~/components/primitives/Button/Button'
import clsx from 'clsx'
import styles from '~/components/primitives/Button/Button.module.scss'

interface ButtonLinkProps extends LocaleLinkProps, ButtonVariantProps {}

/**
 * A `LocaleLink` wrapping element that enforces the button styling on link tags, inheriting the same variant props as the `Button` component.
 */
export const ButtonLink = forwardRef(function ButtonLink(
	{ variant = 'primary', secondary, tertiary, text, hug, className, ...attrs }: ButtonLinkProps,
	ref: React.Ref<HTMLAnchorElement>,
) {
	const variantState: ButtonVariant = useMemo(
		() =>
			getVariantState({
				variant,
				secondary,
				tertiary,
				text,
				hug,
			}),
		[hug, secondary, tertiary, text, variant],
	)

	return (
		<LocaleLink
			ref={ref}
			role="button"
			data-variant={variantState}
			className={clsx(styles.btn, styles[`btn__${variantState}`], className?.trim())}
			{...attrs}
		/>
	)
})
