import { injectScript } from '~/lib/client-only/inject-script'

interface AdobeTargetScriptProps {
	timeout: number
	locale: string
	/** Check if script should be injected into DOM instead of rendered as script tag */
	inject?: boolean
}

export const AdobeTargetScript = ({ timeout, locale, inject = false }: AdobeTargetScriptProps) => {
	const country = locale.split('-')[1].toUpperCase()
	if (inject) {
		injectScript(`/scripts/adobe-target/at.2.10.js?timeout=${timeout}&country=${country}`, false)
		return null
	}

	return (
		<>
			<script defer src={`/scripts/adobe-target/at.2.10.js?timeout=${timeout}&country=${country}`} />
		</>
	)
}
