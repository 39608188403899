import type { Logger } from 'pino'
import logger from '~/lib/logger'

/**
 * Inject the script into the head of the page.
 * @param url
 * @param priority If priority is true, the script will be injected at the top of the head and will be loaded synchronously.
 *                  If priority is false, the script will be injected at the bottom of the head and will be loaded asynchronously (and deferred)
 * @param customLogger
 * @returns
 */
export function injectScript(url: string, priority: boolean, customLogger?: Logger): void {
	if (typeof window === 'undefined' || window?.document?.head.querySelector(`script[src*="${url}"]`)) {
		return
	}

	const script = document.createElement('script')
	script.src = url
	script.defer = !priority
	script.async = !priority

	script.onerror = (): void => {
		const errorMessage = 'Please check if any ad blockers are enabled and try again.'
		;(customLogger || logger).error(`[Tealium] Failed to load script from ${url}. ${errorMessage}`)
	}

	if (priority) {
		document.head.insertBefore(script, document.head.firstChild)
	} else {
		document.head.appendChild(script)
	}
}
