'use client'

import Image from '~/components/primitives/Image'
import errorImageSrc from '~/public/images/error-404.jpg'

import styles from './CustomError.module.scss'
import buttonStyles from '~/components/primitives/Button/Button.module.scss'
import clsx from 'clsx'
import { useFormatMessage } from '~/components/hooks/useFormatMessage'
import Tealium from '~/components/thirdparty/Tealium/Tealium'
import { getPublicConfig } from '~/lib/client-server/config'
import { useAnalyticsSetup } from '~/components/hooks/useAnalytics'
import { useLocale } from '~/components/hooks/useLocale'
import { AdobeTargetScript } from '~/components/thirdparty/AdobeTarget'
import { useEffect, useState } from 'react'
import { ButtonLink } from '~/components/primitives/Button/ButtonLink'

interface CustomErrorProps {
	statusCode?: number
	message?: string
}

export default function CustomerErrorPage({ statusCode, message }: Readonly<CustomErrorProps>) {
	const locale = useLocale()
	const formatMessage = useFormatMessage()
	const displayMessage = !message && !statusCode ? formatMessage('error-unexpected-situation') : message
	const [isReady, setIsReady] = useState(false)

	useEffect(() => {
		const intervalId = setInterval(() => {
			if (typeof window === 'undefined') return () => undefined
			if (window.uaDatalayer?.action) {
				setIsReady(true)
				clearInterval(intervalId)
			}
			return () => clearInterval(intervalId)
		}, 500)
	}, [])

	useAnalyticsSetup({
		allowPageView: statusCode === 404 && isReady,
		pageCategory: ['404 error'],
		page_name: '404 Error',
		page_type: '404',
		site_section: '',
	})

	return (
		<div className={styles['main-wrapper']}>
			{/**
			 * The existing version of Next.js (14.1) is not evaluating Script
			 * tags located in layout for `not-found.tsx` pages.
			 * As a workaround, we're just injecting the scripts again in this component.
			 * Open issue here: https://github.com/vercel/next.js/issues/53108
			 */}
			<AdobeTargetScript inject locale={locale} timeout={getPublicConfig().target.timeout_ms} />
			<Tealium profile={getPublicConfig().tealium.profile} env={getPublicConfig().tealium.env} />
			<div className={styles['text-wrapper']}>
				<div className={styles['error-code']}>
					{displayMessage && <p>{displayMessage}</p>}
					{statusCode && (
						<p>
							{statusCode} {formatMessage('error')}
						</p>
					)}
				</div>

				<div className={styles['header-text']}>{formatMessage('hit-a-wall')}</div>
				<div className={styles['body-text']}>{formatMessage('error-page-copy')}</div>

				<div className={styles.actions}>
					<ButtonLink href="/">{formatMessage('start-fresh')}</ButtonLink>

					<ButtonLink href="/c/new-arrivals">{formatMessage('see-new-gear')}</ButtonLink>
				</div>

				<a
					className={clsx(buttonStyles.btn, buttonStyles.btn__text)}
					data-variant="text"
					href="https://help.underarmour.com/"
				>
					{formatMessage('have-questions')}
				</a>
			</div>

			{/* this is a placeholder image until product is able to provide us with the permanennt image from the mocks in coremedia */}
			<div className={styles['image-wrapper']}>
				<Image
					src={errorImageSrc}
					title={formatMessage('error-404-img-text')}
					alt={formatMessage('error-404-img-text')}
					layout="responsive"
					height="720"
					width="720"
				/>
			</div>
		</div>
	)
}
