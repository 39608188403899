'use client'

import { injectScript } from '~/lib/client-only/inject-script'
import { createClientLogger } from '~/lib/logger'

const logger = createClientLogger('thirdparty')

function TealiumSync({ profile, env }: { profile: string; env: string }) {
	injectScript(`https://tags.tiqcdn.com/utag/underarmour/${profile}/${env}/utag.sync.js`, true, logger)
	return null
}

function TealiumAsync({ profile, env }: { profile: string; env: string }) {
	injectScript(`https://tags.tiqcdn.com/utag/underarmour/${profile}/${env}/utag.js`, false, logger)
	return null
}

export default function Tealium({ profile, env }: { profile: string; env: string }) {
	return (
		<>
			<TealiumSync profile={profile} env={env} />
			<TealiumAsync profile={profile} env={env} />
		</>
	)
}
