import { getImageProps, type ImageProps } from 'next/image'
import { memo, useMemo } from 'react'
import styles from '~/components/primitives/Image/Image.module.scss'
import { getLoader } from '~/lib/next/image'

export interface ResponsiveImageProps extends ImageProps {
	layout?: string
	objectFit?: string
}
const Image = memo(function Image({
	alt,
	src,
	width,
	height,
	layout,
	className,
	objectFit,
	style,
	...imageProps
}: ResponsiveImageProps) {
	const loader = useMemo(() => getLoader(src), [src])
	const classObject = useMemo(() => {
		const classString = [className]
		if (layout === 'responsive') {
			classString.push(styles.responsive_image)
		}
		if (objectFit === 'cover') {
			classString.push(styles['responsive_image--object-fit'])
		}
		return classString.join(' ')
	}, [className, layout, objectFit])

	const imageStyle = useMemo(() => {
		const cssStyle = {
			...style,
		}
		if (layout === 'responsive' && width && height) {
			cssStyle['--image-aspect-ratio'] = `${width} / ${height}`
		}
		return cssStyle
	}, [layout, height, style, width])

	const { props } = getImageProps({
		alt,
		className: classObject,
		loader,
		src,
		width,
		height,
		style: imageStyle,
		...imageProps,
	})

	// Remove transparent color added by Next.js
	if (props.style.color === 'transparent' && props.style.color !== imageStyle.color) delete props.style.color

	// eslint-disable-next-line @next/next/no-img-element
	return <img {...props} alt={alt} />
})
export default Image
